import { FormError } from "../FormError/FormError";

import { FormFieldWrapperStyled } from "./FormFieldWrapper.styled";

export const FormFieldWrapper = ({
  label,
  className,
  showError,
  error,
  children
}) => (
  <FormFieldWrapperStyled
    className={`form-field ${className}`}
    isError={!!error}
  >
    {label ? label : null}
    {children}
    {showError && !!error ? <FormError message={error} /> : null}
  </FormFieldWrapperStyled>
);
