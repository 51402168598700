import { useTranslation } from "react-i18next";

import FormBlock from "pages/New/Dashboard/components/FormBlock/FormBlock";

import { FormCheckbox } from "components/forms/New";

import { StyledBlockAuthBody } from "./BlockAuth.styled";

export const BlockAuth = () => {
  const { t } = useTranslation();
  return (
    <FormBlock
      title={t("dashboard.services.addEdit.blocks.auth.title")}
      subtitle={t("dashboard.services.addEdit.blocks.auth.subtitle")}
    >
      <StyledBlockAuthBody>
        <FormCheckbox name="clientNoAuth" label={t("proxy.auth.noAuth")} />
        <FormCheckbox
          name="clientLoginAuth"
          label={t("proxy.auth.loginAuth")}
        />
        <FormCheckbox name="clientIPAuth" label={t("proxy.auth.ipAuth")} />
      </StyledBlockAuthBody>
    </FormBlock>
  );
};
