import PT from "prop-types";

import IconsSVG from "components/ui/New/Icon/IconsSVG";

import { ErrorCounter } from "./FormError.styled";

export const FormError = ({ message }) => (
  <ErrorCounter>
    <IconsSVG name="inputError" />
    {message}
  </ErrorCounter>
);

FormError.propTypes = {
  message: PT.string.isRequired
};
