import { useRef } from "react";

import PT from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { components } from "react-select";

import Icon from "components/ui/New/Icon/Icon";

import { FormFieldWrapper } from "../FormFieldWrapper/FormFieldWrapper";

import { NoData, ReactSelectStyled } from "./FormSelect.styled";

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Icon name="arrowDown" size="xlarge" />
  </components.DropdownIndicator>
);

const MultiValueRemove = (props) => (
  <components.MultiValueRemove {...props}>
    <Icon name="closeModal" />
  </components.MultiValueRemove>
);

export const FormSelect = ({
  name,
  options,
  defaultValue,
  rules,
  onChange,
  disabled,
  className,
  placeholder,
  multiple,
  label,
  noData,
  showError
}) => {
  // ** Hooks
  const { control } = useFormContext();
  const { t } = useTranslation();
  const ref = useRef(null);

  const NoOptionsMessage = () => (
    <NoData>{noData ? noData : t("noData.select.default")}</NoData>
  );

  const currentDefaultValue = (value) => {
    if (!value) return multiple ? [] : value;
    const type = typeof value;
    if (!multiple) {
      const currentValue = type === "object" ? value?.[0] : value;
      return options.find((option) => option.value === currentValue);
    }
    const currentValue = type === "object" ? value : [value];
    return currentValue.map((item) =>
      options.find((option) => option.value === item)
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <FormFieldWrapper
          className={className}
          label={label}
          error={error?.message}
          showError={showError}
        >
          <ReactSelectStyled
            classNamePrefix="form-select"
            ref={ref}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
              MultiValueRemove,
              NoOptionsMessage
            }}
            isError={!!error}
            placeholder={placeholder}
            isDisabled={disabled}
            // defaultValue={currentDefaultValue(defaultValue)}
            value={currentDefaultValue(field.value)}
            options={options}
            onChange={(option) => {
              if (!option) return;

              if (!multiple) {
                field.onChange(option.value);
                onChange(option.value);
              } else {
                field.onChange(option.map((item) => item.value));
                onChange(option.map((item) => item.value));
              }
            }}
            isClearable={false}
            isMulti={multiple}
          />
        </FormFieldWrapper>
      )}
    />
  );
};

FormSelect.propTypes = {
  name: PT.string.isRequired,
  options: PT.array.isRequired,
  defaultValue: PT.oneOfType([PT.string, PT.array]),
  rules: PT.object,
  onChange: PT.func,
  disabled: PT.bool,
  className: PT.string,
  placeholder: PT.string,
  multiple: PT.bool,
  label: PT.string,
  showError: PT.bool,
  noData: PT.node
};

FormSelect.defaultProps = {
  defaultValue: "",
  rules: {},
  onChange: () => null,
  disabled: false,
  className: "",
  placeholder: "",
  multiple: false,
  label: "",
  showError: false,
  noData: null
};
