import { useState } from "react";

import PT from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import IconsSVG from "components/ui/New/Icon/IconsSVG";

import { FormFieldWrapper } from "../FormFieldWrapper/FormFieldWrapper";

import {
  InputBoxStyled,
  LengthCounterStyled,
  ShowPasswordStyled
} from "./FormInput.styled";

export const FormInput = ({
  id,
  label,
  name,
  rules,
  defaultValue,
  placeholder,
  type,
  readOnly,
  disabled,
  showError,
  className,
  showMaxLenght,
  errorPath,
  rows
}) => {
  // ** Hooks
  const {
    control,
    formState: { errors }
  } = useFormContext();
  //   **Redux state
  const { t } = useTranslation();

  const [showPass, setShowPass] = useState(false);

  const handleShowPass = () => setShowPass((prev) => !prev);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <FormFieldWrapper
          className={className}
          label={label}
          error={error?.message || errors?.[errorPath]?.message}
          showError={showError}
        >
          <InputBoxStyled
            isError={!!error || (errorPath && errors?.[errorPath])}
          >
            {type === "textarea" ? (
              <textarea
                id={id}
                placeholder={placeholder}
                readOnly={readOnly}
                disabled={disabled}
                value={field.value}
                type={"text"}
                onChange={(e) => {
                  e.target.value = e.target.value.trimStart();
                  field.onChange?.(e);
                }}
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                  field.onBlur?.(e);
                }}
                rows={rows}
              />
            ) : (
              <input
                id={id}
                placeholder={placeholder}
                readOnly={readOnly}
                disabled={disabled}
                value={field.value}
                type={type === "password" && showPass ? "text" : type}
                onChange={(e) => {
                  e.target.value = e.target.value.trimStart();
                  if (type === "email") {
                    e.target.value = e.target.value.toLowerCase();
                  }
                  field.onChange?.(e);
                }}
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                  field.onBlur?.(e);
                }}
              />
            )}
            {type === "password" && (
              <ShowPasswordStyled
                aria-label={
                  showPass
                    ? t("forms.password.state.hide")
                    : t("forms.password.state.show")
                }
                type="button"
                onClick={handleShowPass}
                disabled={disabled}
              >
                <IconsSVG name={showPass ? "eye" : "eyeOff"} />
              </ShowPasswordStyled>
            )}
          </InputBoxStyled>
          {showMaxLenght && (
            <LengthCounterStyled isError={field.value.length > showMaxLenght}>
              {field.value.length}/{showMaxLenght}
            </LengthCounterStyled>
          )}
        </FormFieldWrapper>
      )}
    />
  );
};

FormInput.propTypes = {
  id: PT.string,
  label: PT.node,
  name: PT.string.isRequired,
  rules: PT.object,
  defaultValue: PT.string,
  placeholder: PT.string,
  type: PT.string,
  readOnly: PT.bool,
  disabled: PT.bool,
  showError: PT.bool,
  errorPath: PT.string,
  className: PT.string,
  showMaxLenght: PT.number,
  converterOnChange: PT.func,
  rows: PT.number
};

FormInput.defaultProps = {
  id: null,
  label: null,
  rules: {},
  defaultValue: "",
  placeholder: "",
  type: "text",
  readOnly: false,
  disabled: false,
  showError: false,
  className: "",
  showMaxLenght: null,
  errorPath: null
};
