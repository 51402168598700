import { useTranslation } from "react-i18next";

import { Uploader } from "components/common/New/Uploader/Uploader";
import { FormInput, FormSelect } from "components/forms/New";
import Button from "components/ui/New/Button/Button";

import { serviceSelectOptions } from "utils/constants";

import { CardInfo } from "../../../components/CardInfo/CardInfo";
import { FormBlock } from "../../../components/FormBlock/FormBlock";

import {
  BlockLogoStyledBody,
  BlockLogoStyledBodyRow,
  StyledInstructionButtons,
  StyledInstructionText
} from "./BlockLogo.styled";

export const BlockLogo = ({
  setFiles,
  croppedFileObjects,
  setCroppedFileObjects,
  defaultImage,
  setDefaultValues
}) => {
  const { t } = useTranslation();

  return (
    <FormBlock
      title={t("dashboard.services.addEdit.blocks.logo.title")}
      subtitle={t("dashboard.services.addEdit.blocks.logo.subtitle")}
    >
      <BlockLogoStyledBody>
        <Uploader
          onUpload={setFiles}
          defaultImage={defaultImage}
          setDefaultValues={setDefaultValues}
          croppedFileObjects={croppedFileObjects}
          setCroppedFileObjects={setCroppedFileObjects}
        />

        <BlockLogoStyledBodyRow>
          <FormInput
            name={"link"}
            type={"text"}
            label={t("forms.link.label")}
            placeholder={t("forms.link.placeholder")}
            rules={{
              required: t("forms.link.rules.required")
            }}
            showError
          />

          <FormSelect
            name={"category"}
            options={serviceSelectOptions}
            label={t("forms.linkCategory.label")}
            placeholder={t("forms.linkCategory.placeholder")}
            defaultValue={serviceSelectOptions[0].value}
          />
        </BlockLogoStyledBodyRow>

        <CardInfo
          title={t("dashboard.services.addEdit.blocks.logo.instruction.title")}
          noMargin
        >
          <StyledInstructionText>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </StyledInstructionText>

          <StyledInstructionButtons>
            <Button size={"md"}>
              {t("dashboard.services.addEdit.blocks.logo.check")}
            </Button>

            <Button
              size={"md"}
              variant={"outlined"}
              className="button_download_logo"
            >
              {t("dashboard.services.addEdit.blocks.logo.download")}
            </Button>
          </StyledInstructionButtons>
        </CardInfo>
      </BlockLogoStyledBody>
    </FormBlock>
  );
};
