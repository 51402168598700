import styled from "@emotion/styled";

export const StyledSelectBox = styled.div`
  width: 100%;
  margin-bottom: ${(p) => (p.noMargin ? "0" : "16px")};
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 200px;
    margin-right: auto;
  }

  & > div > button {
    padding: 6px 16px;
    height: 32px;
  }
`;

export const StyledBoxHidden = styled.div`
  display: ${(p) => (p.hidden ? "none" : "block")};
  textarea {
    min-height: 100px;
  }
`;
