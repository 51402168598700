import { useEffect, useState } from "react";

import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { FormCheckbox } from "components/forms/New";
import SelectNew from "components/ui/New/Select/SelectNew";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import { useLangUrlDefault } from "hooks";

import styles from "./FreeProxyFilter.module.scss";

export const FreeProxyFilter = ({
  methods,
  defaultValues,
  countries,
  values,
  setValues,
  cities
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [queryLang] = useLangUrlDefault();

  const { proxyTypes } = useSelector(getAllContent);

  const [cityOptions, setCityOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);

  useEffect(() => {
      const options = countries?.map((country) => ({
        value: country.localization["en"]?.toLowerCase(),
        label: country.localization[queryLang]
      }));
      options?.unshift({
        value: "",
        label: t("freeProxy.filter.allCountries")
      });
      setCountryOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, countries]);

  useEffect(() => {
    if (cities) {
      const options = cities?.map((city) => ({
        value: city.name?.toLowerCase(),
        label: city.name
      }));
      options?.unshift({
        value: "",
        label: t("freeProxy.filter.allCities")
      });
      setCityOptions(options?.filter((option) => option.label));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, cities]);

  useEffect(() => {
    const options = proxyTypes.data?.[queryLang]
      ?.filter((item) => item.type !== "FreeProxy")
      .map((proxy) => ({
        value: proxy.type,
        label: proxy.name
      }));
    options?.unshift({
      value: "",
      label: t("freeProxy.filter.allTypes")
    });
    setTypeOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, proxyTypes]);

  useEffect(() => {
    const { country, type, city } = methods.getValues();
    const countryValue =
      countryOptions &&
      (countryOptions?.find(
        (item) =>
          item.value === country?.value ||
          item.value === searchParams.get("country")
      ) ||
        countryOptions[0]);
    const typeValue =
      typeOptions &&
      (typeOptions?.find(
        (item) =>
          item.value === type?.value || item.value === searchParams.get("type")
      ) ||
        typeOptions[0]);
    const cityValue =
      (cityOptions &&
        cityOptions?.find(
          (item) =>
            item.value === city?.value ||
            item.value === searchParams.get("city")
        )) ||
      cityOptions[0];
    setValues({
      ...values,
      country: countryValue,
      type: typeValue,
      city: cityValue
    });
    methods.setValue("country", countryValue);
    methods.setValue("city", cityValue);
    methods.setValue("type", typeValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, countryOptions, typeOptions, cityOptions]);

  const handleChange = (name, option) => {
    methods.setValue(name, option);
    setValues({ ...values, [name]: option });
  };

  return (
    <FormProvider {...methods}>
      <form className={styles.form} noValidate>
        <SelectNew
          name="country"
          id="country"
          placeholder={t("freeProxy.filter.selectCountry")}
          label={t("freeProxy.filter.country")}
          defaultValue={defaultValues.country}
          labelSize="large"
          color="dark"
          options={countryOptions}
          value={values.country}
          className={styles.select}
          wrapperClassName={styles.select__wrapper}
          onChange={(option) => {
            handleChange("country", option);
          }}
        />
        <SelectNew
          name="city"
          id="city"
          placeholder={t("freeProxy.filter.selectCity")}
          label={t("freeProxy.filter.city")}
          defaultValue={defaultValues.city}
          color="dark"
          value={values.city}
          options={cityOptions}
          labelSize="large"
          className={styles.select}
          wrapperClassName={styles.select__wrapper}
          onChange={(option) => {
            handleChange("city", option);
          }}
        />
        <SelectNew
          name="type"
          id="type"
          placeholder={t("freeProxy.filter.typeOf")}
          label={t("freeProxy.filter.typeOf")}
          color="dark"
          labelSize="large"
          className={styles.select}
          value={values.type}
          wrapperClassName={styles.select__wrapper}
          options={typeOptions}
          defaultValue={defaultValues.type}
          onChange={(data) => {
            handleChange("type", data);
          }}
        />
        <div className={styles.radio_inputs}>
          <span className={styles.radio_inputs__label}>Google</span>
          <div className={styles.radio_inputs__wrapper}>
            <FormCheckbox
              name="google"
              type="radio"
              label={t("ui.yesNo.yes")}
              radioValue="yes"
              radioClearable
            />
            <FormCheckbox
              name="google"
              type="radio"
              label={t("ui.yesNo.no")}
              radioValue="no"
              radioClearable
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
