import styled from "@emotion/styled";
import ReactSelect from "react-select";

export const ReactSelectStyled = styled(ReactSelect)`
  border: none;
  width: 100%;
  .form-select__control {
    gap: 8px;
    height: 48px;
    width: 100%;
    padding: 11px 15px;

    border-radius: 8px;
    border: 1px solid
      ${(p) =>
        p.isError
          ? p.theme.colors["red-500"]
          : p.theme.colors["neutral-100"]} !important;
    background-color: ${(p) =>
      p.isError ? "#fff5f54d" : p.theme.colors.white};

    transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    &:hover {
      border: 1px solid
        ${(p) =>
          p.isError
            ? p.theme.colors["red-500"]
            : p.theme.colors["neutral-500"]} !important;
      background-color: ${(p) =>
        p.isError ? "#fff5f54d" : p.theme.colors.white};
      transition: border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;
    }

    &--is-focused {
      border: 1px solid
        ${(p) =>
          p.isError
            ? p.theme.colors["red-500"]
            : p.theme.colors["main-700"]} !important;
      background-color: ${(p) => (p.isError ? "#fff5f54d" : "#f7ffe926")};
      box-shadow: none;

      transition: border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;

      &:hover {
        border: 1px solid
          ${(p) =>
            p.isError
              ? p.theme.colors["red-500"]
              : p.theme.colors["main-700"]} !important;
        background-color: ${(p) => (p.isError ? "#fff5f54d" : "#f7ffe926")};
        transition: border-color 0.3s ease-in-out,
          background-color 0.3s ease-in-out;
      }
    }

    &--is-disabled {
      background-color: ${(p) => p.theme.colors["background-color"]};
      color: ${(p) => p.theme.colors["neutral-300"]};
    }
  }

  .form-select__indicators,
  .form-select__indicator {
    font-size: 0;
    padding: 0;
    color: ${(p) => p.theme.colors["neutral-800"]};
  }

  .form-select__indicator {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
  }

  .form-select__control--menu-is-open .form-select__indicator {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }

  .form-select__control--is-disabled .form-select__indicator {
    color: ${(p) => p.theme.colors["neutral-300"]};
  }

  .form-select__value-container {
    padding: 0;

    font-family: ${(p) => p.theme.fontFamily.lato};
    font-size: ${(p) => p.theme.fontSizes.m};
    font-style: ${(p) => p.theme.fontStyle.normal};
    font-weight: ${(p) => p.theme.fontWeight.normal};
    line-height: 150%;
  }

  .form-select__placeholder {
    margin: 0;
    color: ${(p) => p.theme.colors["neutral-300"]};
  }

  .form-select__input-container {
    margin: 0;
    padding: 0;
  }

  .form-select__menu {
    margin: 0;
    margin-top: 1px;
    border-radius: 8px;
    padding: 12px 6px;
    box-shadow: 0px 7px 21px 0px rgba(135, 140, 189, 0.23);
    background-color: ${(p) => p.theme.colors.white};

    font-family: ${(p) => p.theme.fontFamily.lato};
    font-size: ${(p) => p.theme.fontSizes.m};
    font-style: ${(p) => p.theme.fontStyle.normal};
    font-weight: ${(p) => p.theme.fontWeight.normal};
    line-height: 150%;

    &-list {
      padding: 0 6px;
      max-height: 300px;
      overflow: auto;
    }
  }

  .form-select__option {
    width: 100%;
    max-width: 100%;
    padding: 10px;
    overflow: hidden;
    border-radius: 8px;

    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors["neutral-800"]} !important;
    transition: background-color 0.3s ease-in-out;

    &--is-focused {
      background-color: #f6f7f9cc;
      transition: background-color 0.3s ease-in-out;
    }

    &--is-selected {
      background-color: #f6f7f9cc;
      transition: background-color 0.3s ease-in-out;
    }
  }

  .form-select__value-container--is-multi {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
    flex-wrap: nowrap;
    gap: 4px;
  }

  .form-select__multi-value {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 0;
    padding: 0 12px;
    background-color: ${(p) => p.theme.colors["background-color"]};
    border-radius: 8px;
    &__label {
      padding: 0;
      font-size: ${(p) => p.theme.fontSizes.m};
      line-height: 150%;
    }

    &__remove {
      padding: 0;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: transparent;
        color: ${(p) => p.theme.colors["neutral-800"]};
      }

      & > svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`;

export const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
  padding: 10px;
  border-radius: 8px;
  color: ${(p) => p.theme.colors["neutral-300"]};
  background-color: ${(p) => p.theme.colors.white};
`;
