import { useTranslation } from "react-i18next";

import { FormStars } from "components/forms/New";
import Card from "components/ui/New/Card/Card";

import styles from "./Ratings.module.scss";

export const Ratings = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Card size={"small"} className={styles.card}>
      <div
        className={`${styles.card_title} ${className}
        `}
      >
        {t("modals.review.form.ratings.title")}
      </div>
      <FormStars
        name="priceRating"
        label={t("modals.review.form.ratings.price")}
        defaultValue={0}
        tooltipPrefixText={t("modals.review.form.ratings.rating")}
      />
      <FormStars
        name="speedRating"
        label={t("modals.review.form.ratings.speed")}
        defaultValue={0}
        tooltipPrefixText={t("modals.review.form.ratings.rating")}
      />
      <FormStars
        name="reliabilityRating"
        label={t("modals.review.form.ratings.reliability")}
        defaultValue={0}
        tooltipPrefixText={t("modals.review.form.ratings.rating")}
      />
      <FormStars
        name="supportRating"
        label={t("modals.review.form.ratings.support")}
        defaultValue={0}
        tooltipPrefixText={t("modals.review.form.ratings.rating")}
      />
    </Card>
  );
};
