import styled from "@emotion/styled";

import Select from "components/ui/New/Select/Select";

export const StyledAdvantagesHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
  }
`;

export const StyledAdvantagesTitle = styled.span`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 100%;
`;

export const StyledAdvantagesSelect = styled(Select)`
  padding: 6px 16px;
  height: 32px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 200px;
    margin-left: auto;
  }
`;

export const StyledAdvantagesBox = styled.div`
  ${(p) => (p.hidden ? "display: none;" : "")}
`;
