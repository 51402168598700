import styled from "@emotion/styled";

import IconsSVG from "components/ui/New/Icon/IconsSVG";

export const FormDateBoxStyled = styled.div`
  width: 100%;
  input {
    width: 100%;
    width: 100%;
    padding: 0 40px 0 16px;
    height: 40px;
    border-radius: 8px;
    background-image: linear-gradient(
      to left,
      ${(p) => p.theme.colors["background-color"]} 0 40px,
      ${(p) => (p.isError ? "#fff5f54d" : p.theme.colors.white)} 40px
    );
    outline: none;
    border: 1px solid
      ${(p) =>
        p.isError ? p.theme.colors["red-500"] : p.theme.colors["neutral-100"]};

    transition: border-color 0.3s ease-in-out, background-image 0.3s ease-in-out;

    &:hover {
      border-color: ${(p) =>
        p.isError ? p.theme.colors["red-500"] : p.theme.colors["neutral-500"]};
      background-image: linear-gradient(
        to left,
        ${(p) => p.theme.colors["background-color"]} 0 40px,
        ${(p) => (p.isError ? "#fff5f54d" : p.theme.colors.white)} 40px
      );
      transition: border-color 0.3s ease-in-out,
        background-image 0.3s ease-in-out;
    }

    &[data-focus-visible-added] {
      border: 1px solid
        ${(p) =>
          p.isError ? p.theme.colors["red-500"] : p.theme.colors["main-700"]};
      background-image: linear-gradient(
        to left,
        ${(p) => p.theme.colors["background-color"]} 0 40px,
        ${(p) => (p.isError ? "#fff5f54d" : "#f7ffe926")} 40px
      );
      transition: border-color 0.3s ease-in-out,
        background-image 0.3s ease-in-out;
    }
  }

  .react-datepicker-popper {
    padding: 0;
    height: fit-content;
    background: transparent;
    border-radius: 12px;
    & > .react-datepicker__triangle {
      display: none !important;
    }
  }
`;

export const FormDateCalendarIcon = styled(IconsSVG)`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  font-size: 0;
  line-height: 0;
`;

export const CalendarContainer = styled.div`
  position: relative;
  border: 0;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 14px 24px 0px rgba(135, 140, 189, 0.12);

  @media (max-width: 540px) {
    max-width: min-content;
  }
  .react-datepicker__aria-live {
    display: none;
  }

  .react-datepicker__navigation {
    top: 6px;
  }

  .react-datepicker__header {
    background-color: ${(p) => p.theme.colors.white};
    border: 0;
    padding: 0;
  }

  .react-datepicker__current-month {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: 8px 12px;

    color: ${(p) => p.theme.colors["neutral-800"]};
    font-family: ${(p) => p.theme.fontFamily.lato};
    font-size: ${(p) => p.theme.fontSizes.s};
    font-style: ${(p) => p.theme.fontStyle.normal};
    font-weight: 600;
    line-height: 120%;

    border-bottom: 1px solid rgba(216, 218, 224, 0.3);
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: center;
    gap: 8px;
    padding: 12px;
  }

  .react-datepicker__day-name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 0;
    color: #9e9eb0;
    font-family: ${(p) => p.theme.fontFamily.lato};
    font-size: ${(p) => p.theme.fontSizes.xxs};
    font-style: ${(p) => p.theme.fontStyle.normal};
    font-weight: ${(p) => p.theme.fontWeight.normal};
    line-height: 130%;
  }

  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
    padding: 12px;

    .react-datepicker__week {
      display: flex;
      gap: 8px;
    }

    .react-datepicker__day {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin: 0;
      color: ${(p) => p.theme.colors["neutral-800"]};
      font-family: ${(p) => p.theme.fontFamily.lato};
      font-size: ${(p) => p.theme.fontSizes.s};
      font-style: ${(p) => p.theme.fontStyle.normal};
      font-weight: ${(p) => p.theme.fontWeight.normal};
      line-height: 130%;

      border-radius: 4px;

      &.react-datepicker__day--outside-month {
        color: ${(p) => p.theme.colors["neutral-300"]};
      }

      &.react-datepicker__day--in-range,
      &.react-datepicker__day--in-selecting-range {
        background-color: #e8f8cc57;
      }

      &.react-datepicker__day--selected,
      &.react-datepicker__day--keyboard-selected,
      &.react-datepicker__day--range-start,
      &.react-datepicker__day--range-end,
      &.react-datepicker__day--in-selecting-start,
      &.react-datepicker__day--in-selecting-end {
        background-color: ${(p) => p.theme.colors["main-500"]};
      }
    }
  }
`;
