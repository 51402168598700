import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FormCheckbox, FormInput } from "components/forms/New";

import { ApiService } from "services";

import { useDispatchedActions, useLangUrlDefault } from "hooks";

import { LoginSchema } from "utils/validation";

import {
  StyledAuthForm,
  StyledAuthTitle,
  StyledButtonSubmit,
  StyledRecoveryBox,
  StyledRecoveryButton
} from "../AuthPages.styled";
import { AuthNavigate } from "../components/AuthNavigate/AuthNavigate";

import { ModalEmailCofigmed } from "./ModalEmailCofigmed/ModalEmailCofigmed";

const LoginPage = () => {
  // **Props
  const [, hrefLang] = useLangUrlDefault();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // **Redux state
  const { t } = useTranslation();

  // **Local state
  const [adminUser, setAdminUser] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isInProcess, setIsInProcess] = useState(true);

  // Dispatch
  const { setUser, setAuthentication, setIsRedirectToCabinet } =
    useDispatchedActions();

  // Form
  const methods = useForm({
    resolver: yupResolver(LoginSchema(t("forms", { returnObjects: true })))
  });

  const getAdminUser = async () => {
    try {
      const response = await ApiService.getAdminUser();

      if (response && response.status !== 200) {
        throw response;
      }

      if (!response?.data) {
        setAdminUser(false);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      toast.error(t("notifications.apiError"));
    }
  };

  const createDefaultUser = async (data) => {
    try {
      const response = await ApiService.createDefaultUser(
        data.email,
        data.password
      );

      if (response && response.status !== 200) {
        throw response;
      }

      window.location.reload();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      toast.error(t("notifications.apiError"));
    }
  };

  const modalHandler = () => {
    searchParams.delete("token");
    setIsModalOpened(false);

    if (!isConfirmed) {
      navigate(`${hrefLang}/registration/`);
    }
  };

  const checkToken = async () => {
    try {
      const response = await ApiService.confirmEmail(searchParams.get("token"));

      if (response && response.status !== 200) {
        throw response;
      }

      if (response.data) {
        setIsConfirmed(true);
      } else {
        setIsConfirmed(false);
      }
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setIsInProcess(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const res = await ApiService.login(
        data.email.toLowerCase(),
        data.password,
        data.remember
      );
      if (res && res.status !== 200) {
        if (res.response.status === 401) {
          const error = {
            type: "manual",
            message: t("notifications.authError.401")
          };
          methods.setError("email", error);
          methods.setError("password", error);
          // eslint-disable-next-line no-throw-literal
          throw {
            response: res,
            message: t(`notifications.authError.${res.response.status}`)
          };
        }

        if (res.response.status === 561) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response: res,
            message: t(`notifications.authError.${res.response.status}`)
          };
        }

        if (res.response.status === 560) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response: res,
            message: t(`notifications.authError.${res.response.status}`)
          };
        }

        if (res.response.status === 543) {
          // eslint-disable-next-line no-throw-literal
          throw {
            response: res,
            message: t(`notifications.authError.${res.response.status}`)
          };
        }

        throw res;
      }

      const userInfo = await ApiService.getUserAuth();

      if (userInfo && userInfo.status !== 200) {
        throw res;
      }

      setUser(userInfo?.data);
      setAuthentication(true);

      setIsRedirectToCabinet(true);

      navigate(`${hrefLang}/new/dashboard/profile/`, { replace: true });
    } catch (err) {
      toast.error(err?.message || t("notifications.apiError"));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchParams.get("token")) {
      setIsModalOpened(true);

      checkToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    getAdminUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AuthNavigate to="registration" />
      <StyledAuthTitle noSub>{t("authPages.signIn.title")}</StyledAuthTitle>
      <FormProvider {...methods}>
        <StyledAuthForm
          onSubmit={methods.handleSubmit(
            adminUser ? onSubmit : createDefaultUser
          )}
          noValidate
        >
          <FormInput
            name="email"
            type={"email"}
            placeholder={t("forms.email.label")}
            label={t("forms.email.label")}
            showError
          />
          <FormInput
            name="password"
            type={"password"}
            placeholder={t("forms.password.placeholder")}
            label={t("forms.password.placeholder")}
            showError
          />
          <StyledRecoveryBox>
            <FormCheckbox
              name="remember"
              label={t("authPages.signIn.remember")}
            />
            <StyledRecoveryButton
              color={"secondary"}
              size={"md"}
              linkTo={`${hrefLang}/new/recovery/`}
            >
              {t("authPages.signIn.forgot")}
            </StyledRecoveryButton>
          </StyledRecoveryBox>

          <StyledButtonSubmit
            type="submit"
            fullWidth
            disabled={isLoading}
            loading={isLoading}
          >
            {t("authPages.signIn.btn")}
          </StyledButtonSubmit>
        </StyledAuthForm>
      </FormProvider>
      <ModalEmailCofigmed
        open={isModalOpened}
        onClose={modalHandler}
        isConfirmed={isConfirmed}
        isInProcess={isInProcess}
      />
    </>
  );
};

export default LoginPage;
