import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CardInfo } from "pages/New/Dashboard/components/CardInfo/CardInfo";

import { FormInput, FormSelect } from "components/forms/New";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import { useLangUrlDefault } from "hooks";

import { transformMinRent } from "utils/helpers";

import { Countries } from "../Countries/Countries";

import { StyledRow } from "./ProxyTypeInfo.styled";

export const ProxyTypeInfo = ({ data }) => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  const { rentPeriods } = useSelector(getAllContent);

  const { setValue } = useFormContext();
  const prefix = `proxy.${data.type}.`;

  const rents = transformMinRent(
    rentPeriods?.data,
    queryLang,
    t("times", { returnObjects: true })
  );

  const handleCloseCardClick = () => {
    setValue(`proxyTypes.${data.type}`, false);
  };

  return (
    <CardInfo title={data?.name} onClose={handleCloseCardClick}>
      <StyledRow>
        <FormInput name={`${prefix}link`} label={"Link"} showError />
      </StyledRow>
      <StyledRow>
        <FormSelect
          name={`${prefix}minRent`}
          label={t("forms.minRent.label")}
          options={rents}
          showError
        />
        <FormInput
          name={`${prefix}minPrice`}
          type="number"
          label={t("forms.minPrice.label")}
          placeholder={"$"}
          showError
        />
      </StyledRow>
      {data?.type === "ResidentialProxy" ? (
        <StyledRow>
          <FormInput
            name={`${prefix}pullIp`}
            label={t("forms.pullIP.label")}
            type={"number"}
            showError
          />
          <FormInput
            name={`${prefix}minGb`}
            type={"number"}
            label={t("forms.minGB.label")}
            showError
          />
        </StyledRow>
      ) : (
        <StyledRow>
          <Countries prefix={prefix} type={data.type} />
        </StyledRow>
      )}
    </CardInfo>
  );
};
