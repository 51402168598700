import { useEffect, useState } from "react";

import { useFormContext, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

import FormBlock from "pages/New/Dashboard/components/FormBlock/FormBlock";

import { FormInput } from "components/forms/New";
import LabelCountry from "components/ui/New/LabelContry/LabelCountry";
import Select from "components/ui/New/Select/Select";

import { useLangUrlDefault } from "hooks";

import { StyledBoxHidden, StyledSelectBox } from "./BlockDescriptions.styled";

export const BlockDescriptions = () => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  const { errors } = useFormState();
  const { getValues, watch, clearErrors } = useFormContext();
  const { description } = watch();
  const [lang, setLang] = useState(queryLang);

  const options = [
    { value: "en", label: <LabelCountry code="en">English</LabelCountry> },
    { value: "ru", label: <LabelCountry code="ru">Russian</LabelCountry> },
    { value: "ua", label: <LabelCountry code="ua">Ukrainian</LabelCountry> }
  ];

  useEffect(() => {
    // console.log("errors", errors);
    const { description } = getValues();
    if (errors?.description?.type === "description-test") {
      const lang = options.find(
        (option) => !description?.[option.value]
      )?.value;
      if (lang) {
        setLang(lang);
      }
      return;
    }
    if (errors?.description?.type === "description-min") {
      const lang = options.find(
        (option) => description[option.value].length < 50
      )?.value;
      if (lang) {
        setLang(lang);
      }
      return;
    }
    if (errors?.description?.type === "description-max") {
      const lang = options.find(
        (option) => description[option.value].length > 100
      )?.value;
      if (lang) {
        setLang(lang);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (description) {
      const keys = Object.keys(description);
      if (
        (keys.every((key) => description[key].length > 0) &&
          errors?.description?.type === "description-test") ||
        (keys.every((key) => description[key].length >= 50) &&
          errors?.description?.type === "description-min") ||
        (keys.every((key) => description[key].length <= 100) &&
          errors?.description?.type === "description-max")
      ) {
        clearErrors("description");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description?.[lang], errors]);

  return (
    <FormBlock
      title={t("dashboard.services.addEdit.blocks.description.title")}
      subtitle={t("dashboard.services.addEdit.blocks.description.subtitle")}
    >
      <StyledSelectBox>
        <Select
          name={"lang"}
          defaultValue={queryLang}
          renderValue={(selected) =>
            options.find((option) => option.value === selected?.value)?.label
          }
          value={lang}
          onChange={setLang}
          options={options}
        />
      </StyledSelectBox>
      {options.map((option) => (
        <StyledBoxHidden hidden={lang !== option.value} key={option.value}>
          <FormInput
            name={`description.${option.value}`}
            label={t("forms.description.label")}
            placeholder={t("forms.description.placeholder")}
            type={"textarea"}
            showMaxLenght={500}
            showError
            showParentError
            parentErrorPath={"description"}
          />
        </StyledBoxHidden>
      ))}
    </FormBlock>
  );
};
