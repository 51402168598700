import styled from "@emotion/styled";

export const LabelStyled = styled.span`
  color: ${(p) => p.theme.colors["neutral-800"]};

  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 140%;
  text-transform: uppercase;
  margin-bottom: 7px;
`;
